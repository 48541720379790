import React, { useState, useEffect, useRef, useContext } from "react";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from 'primereact/inputswitch';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { Image } from 'primereact/image';
import { ColorPicker } from 'primereact/colorpicker';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import LinkDialog from "./linkDialog";
import Editor from "../../layout/Editor/Editor";
import { appContext } from '../../../App'

function PortalEdit(props) {
    const navigate = useNavigate();
    const [portal, setPortal] = useState([]);
    let { portalId } = useParams(null);
    const [steps, setSteps] = useState([]);
    const AppContext = useContext(appContext)
    const [currentLink, setCurrentLink] = useState(false)

    useEffect(() => {
        if (props?.mode && props.mode == 'create') {
            let s = {
                name: 'Nouveau portal',
                fields: [],
                enabled: false
            }
            setPortal(s);
        }
        else {
            AppContext.api.getRecord("portals", [portalId]).then((res) => {
                setPortal(res.data);
            })
        }

        
        let fields = [
            'steps.id',
            'steps.name'
        ]
        let params = [{key:'include',value:fields.join(',')}]

        AppContext.api.getRecords("steps", params).then((res) => {
            setSteps(res.data.records);
        });

    }, []);

    const startContent = (
        <React.Fragment>

        </React.Fragment>
    );

    const endContent = (
        <React.Fragment>
            <span className="p-buttonset">
                <Button label="Sauvegarder" icon="pi pi-check" onClick={() => savePortal()} severity="success" ></Button>
            </span>
        </React.Fragment>
    );

    const savePortal = () => {
        let errors = {}
        if (portal.name == '') errors['name'] = 'Champs "Nom de l\'organisme" requis.'
        if (portal.title == '') errors['title'] = 'Champs "Intitulé de l\'organisme" requis.'
        if (portal.code == '') errors['code'] = 'Champs "Code d\'identification" requis.'

        if (Object.keys(errors).length > 0) {
            let msg = [];
            for (const name in errors) msg.push(errors[name])
            AppContext.showNotification({
                severity: 'error',
                summary: 'Erreurs',
                detail: msg.map((m, i) => <div key={i}>{m}</div>),
                life: 3000
            });
            return;
        }

        if (portal?.id && portal.id) {
            AppContext.api.putRecords("portals", [portalId], portal).then((res) => {
                AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Guichet sauvegardé', life: 3000 });
            })
        }
        else {
            AppContext.api.postRecords("portals", portal).then((res) => {
                AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Guichet sauvegardé', life: 3000 });
                setPortal({ ...portal, id: res.data });
                navigate(`/config/portals/${res.data}/edit`);
            })
        }
    }

    const setProperty = (name, value) => {
        let v = (value === undefined) ? null : value;
        setPortal({ ...portal, [name]: v });
    }

    const selectImage = (property, url) => {
        setProperty(property, url)
    }

    const tabviewPt = {
        root: { className: 'flex flex-column flex-grow-1 p-0', style: { height: 0 } },
        panelContainer: { className: 'flex-grow-1', style: { 'height': 0, overflowY: 'auto' } }
    }

    const saveCurrentLink = (link) => {
        var array = [...portal.links];
        var index = array.indexOf(currentLink)
        if (index !== -1) {
            array[index] = link;
            setPortal({ ...portal, links: array });
        }
        setCurrentLink(false)
    }

    const actionLinksBtns = (item) => {
        return (
            <React.Fragment>
                <span className="p-buttonset">
                    <Button
                        icon="pi pi-pencil"
                        onClick={() => setCurrentLink(item)}
                        text raised
                    ></Button>
                    <Button
                        icon="pi pi-trash"
                        onClick={(event) => confirmRemoveLink(event, item)}
                        severity="danger"
                        text raised
                    ></Button>
                </span>
            </React.Fragment>
        );
    };

    const confirmRemoveLink = (event, link) => {

        const accept = () => {
            const links = { ...portal.links }
            var array = [...portal.links];
            var index = array.indexOf(link)
            if (index !== -1) {
                array.splice(index, 1);
                setProperty('links', array)
            }
        };

        confirmPopup({
            target: event.currentTarget,
            message: 'Voulez-vous supprimer ce lien?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'reject',
            accept
        });
    };

    const addNewLink = () => {
        var array = [...portal.links];
        const link = {
            icon: 'fa-globe',
            name: '',
            url: ''
        }
        array.push(link)
        setProperty('links', array)
        setCurrentLink(link)
    }

    return (
        <React.Fragment>
            <ConfirmPopup />
            <LinkDialog currentLink={currentLink} onChange={saveCurrentLink} onClose={() => setCurrentLink(false)} />
            <Card
                className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
                header={
                    <Toolbar
                        start={<h2 className="m-0">Guichet</h2>}
                        end={endContent}
                        className="shadow-none border-bottom-1 surface-border border-noround-bottom"
                    />
                }>
                <div className="flex flex-grow-1">
                    <div className="flex-grow-1 flex flex-column">
                        <TabView pt={tabviewPt} className="flex-grow-1">
                            <TabPanel header="Informations générales">
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="name">Nom de l'organisme : *</label>
                                        <InputText id="name" className="w-full" value={portal.name} onChange={(e) => setProperty('name', e.target.value)} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="title">Intitulé de l'organisme (ex : Mairie de Plassans, Club de foot, etc.) : *</label>
                                        <InputText id="title" className="w-full" value={portal.title} onChange={(e) => setProperty('title', e.target.value)} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="code">Code d'identification de l'organisme : *</label>
                                        <InputText id="code" className="w-full" value={portal.code} onChange={(e) => setProperty('code', e.target.value)} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="enabled">Guichet activé / désactivé</label>
                                        <div className="w-full"><InputSwitch id="enabled" checked={portal.enabled} onChange={(e) => setProperty('enabled', e.value)} /></div>
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="contact_address">Adresse 1</label>
                                        <InputText id="contact_address" className="w-full" value={portal.contact_address} onChange={(e) => setProperty('contact_address', e.target.value)} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="contact_address2">Adresse 2</label>
                                        <InputText id="contact_address2" className="w-full" value={portal.contact_address2} onChange={(e) => setProperty('contact_address2', e.target.value)} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="contact_zip">Code postal</label>
                                        <InputText id="contact_zip" className="w-full" value={portal.contact_zip} onChange={(e) => setProperty('contact_zip', e.target.value)} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="contact_city">Ville</label>
                                        <InputText id="contact_city" className="w-full" value={portal.contact_city} onChange={(e) => setProperty('contact_city', e.target.value)} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="contact_phone">Téléphone</label>
                                        <InputText id="contact_phone" className="w-full" value={portal.contact_phone} onChange={(e) => setProperty('contact_phone', e.target.value)} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="contact_email">Email</label>
                                        <InputText id="contact_email" className="w-full" value={portal.contact_email} onChange={(e) => setProperty('contact_email', e.target.value)} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="contact_website">Site internet</label>
                                        <InputText id="contact_website" className="w-full" value={portal.contact_website} onChange={(e) => setProperty('contact_website', e.target.value)} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="form_solution_url">URL FormSolution</label>
                                        <InputText id="form_solution_url" className="w-full" value={portal.form_solution_url} onChange={(e) => setProperty('form_solution_url', e.target.value)} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="meta_description">Phrase de description du guichet pour les moteurs de recherche :</label>
                                        <InputText id="meta_description" className="w-full" value={portal.meta_description} onChange={(e) => setProperty('meta_description', e.target.value)} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="save_submissions">Enregistrer les soumissions non envoyées :</label>
                                        <div className="w-full"><InputSwitch id="save_submissions" checked={portal.save_submissions} onChange={(e) => setProperty('save_submissions', e.value)} /></div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Liens">
                                    <p>Les pages statiques qui s'afficheront dans le pied de page (footer) de ce guichet</p>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="step_privacy_id">Page vie privée :</label>
                                        <Dropdown
                                            id="step_privacy_id"
                                            value={portal.step_privacy_id}
                                            onChange={(e) => setProperty('step_privacy_id', e.value)}
                                            options={steps}
                                            optionLabel="name"
                                            optionValue="id"
                                            filter showClear placeholder="Selectionner une page"
                                            className="w-full" />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="step_accessibility_id">Page accessibilité :</label>
                                        <Dropdown
                                            id="step_accessibility_id"
                                            value={portal.step_accessibility_id}
                                            onChange={(e) => setProperty('step_accessibility_id', e.value)}
                                            options={steps}
                                            optionLabel="name"
                                            optionValue="id"
                                            filter showClear placeholder="Selectionner une page"
                                            className="w-full" />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="step_legal_id">Page conditions d'utilisation :</label>
                                        <Dropdown
                                            id="step_legal_id"
                                            value={portal.step_legal_id}
                                            onChange={(e) => setProperty('step_legal_id', e.value)}
                                            options={steps}
                                            optionLabel="name"
                                            optionValue="id"
                                            filter showClear placeholder="Selectionner une page"
                                            className="w-full" />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="step_help_id">Page aide :</label>
                                        <Dropdown
                                            id="step_help_id"
                                            value={portal.step_help_id}
                                            onChange={(e) => setProperty('step_help_id', e.value)}
                                            options={steps}
                                            optionLabel="name"
                                            optionValue="id"
                                            filter showClear placeholder="Selectionner une page"
                                            className="w-full" />
                                    </div>
                                </div>
                                <p>Les icônes et liens qui s'affichent dans le haut de page (header) du guichet</p>
                                <DataTable value={portal.links} reorderableRows onRowReorder={(e) => setProperty('links', e.value)}>
                                    <Column rowReorder style={{ width: '3rem' }} />
                                    <Column field="icon" header="Icone" style={{ width: '45px' }} body={(item) => <div className="text-center"><span className={'fa ' + item.icon}></span></div>}></Column>
                                    <Column field="title" header="Name"></Column>
                                    <Column field="url" header="URL" bodyStyle={(item) => {
                                        return <><span>{item.url}</span><Button size="small" text raised icon="pi pi-link" label="lien"></Button></>
                                    }}></Column>
                                    <Column 
                                        body={actionLinksBtns}
                                        header={
                                            <Button
                                                severity="success"
                                                size="small"
                                                onClick={addNewLink}
                                                icon="pi pi-plus">
                                            </Button>}
                                        style={{ width: '85px' }} ></Column>
                                </DataTable>
                            </TabPanel>
                            <TabPanel header="RGPD">
                                <div>
                                    <h2>Votre DPO *</h2>
                                    <p>Le RGPD (ou GDPR) est le règlement européen sur la protection des données. Cette nouvelle réglementation européenne est entrée en vigueur le 25 mai 2018 et vise à renforcer les droits des personnes et à responsabiliser les acteurs traitant des données.</p>
                                    <p>Le délégué est chargé de piloter la conformité au règlement européen sur la protection des données au sein de l’organisme qui l’a désigné, s’agissant de l’ensemble des traitements mis en œuvre par cet organisme. Sa désignation est obligatoire dans certains cas. Sous réserves de certaines conditions, un délégué, interne ou externe, peut être désigné pour plusieurs organismes.</p>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="rgpd_dpo_contact_name">Nom</label>
                                        <InputText id="rgpd_dpo_contact_name" className="w-full" value={portal.rgpd_dpo_contact_name} onChange={(e) => setProperty('rgpd_dpo_contact_name', e.target.value)} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="rgpd_dpo_contact_email">Email</label>
                                        <InputText id="rgpd_dpo_contact_email" className="w-full" value={portal.rgpd_dpo_contact_email} onChange={(e) => setProperty('rgpd_dpo_contact_email', e.target.value)} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="rgpd_dpo_contact_phone">Téléphone</label>
                                        <InputText id="rgpd_dpo_contact_phone" className="w-full" value={portal.rgpd_dpo_contact_phone} onChange={(e) => setProperty('rgpd_dpo_contact_phone', e.target.value)} />
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h2>Votre responsable du traitement des données *</h2>
                                    <p>Le responsable d'un traitement de données à caractère personnel est en principe la personne, l'autorité publique, la société ou l'organisme qui détermine les finalités et les moyens de ce fichier, qui décide de sa création. En pratique, il s'agit généralement de la personne morale (entreprise, collectivité, etc.) incarnée par son représentant légal (président, maire, etc.).</p>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="rgpd_data_contact_name">Nom</label>
                                        <InputText id="rgpd_data_contact_name" className="w-full" value={portal.rgpd_data_contact_name} onChange={(e) => setProperty('rgpd_data_contact_name', e.target.value)} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="rgdp_data_contact_email">Email</label>
                                        <InputText id="rgdp_data_contact_email" className="w-full" value={portal.rgpd_data_contact_email} onChange={(e) => setProperty('rgpd_data_contact_email', e.target.value)} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="rgdp_data_contact_phone">Téléphone</label>
                                        <InputText id="rgdp_data_contact_phone" className="w-full" value={portal.rgpd_data_contact_phone} onChange={(e) => setProperty('rgpd_data_contact_phone', e.target.value)} />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Mise en page">
                                <p>Vous pouvez personnaliser ici la page d'accueil de votre guichet.</p>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label className="w-full" htmlFor="rgdp_data_contact_name">Logo</label>
                                        <Image src={portal.logo} alt="Image" width="250" preview />
                                        <div>
                                            <Button onClick={() => AppContext.pickMedia((url) => selectImage('logo', url), () => { })} label="Sélectionner" />
                                            <Button onClick={() => setProperty('logo', null)} label="Enlever" severity="danger" />
                                        </div>
                                    </div>
                                    <div className="field col">
                                        <label className="w-full" htmlFor="rgdp_data_contact_name">Bannière</label>
                                        <Image src={portal.banner} alt="Image" width="250" preview />
                                        <div>
                                            <Button onClick={() => AppContext.pickMedia((url) => selectImage('banner', url), () => { })} label="Sélectionner" />
                                            <Button onClick={() => setProperty('banner', null)} label="Enlever" severity="danger" />
                                        </div>
                                    </div>
                                    <div className="field col">
                                        <label className="w-full flex align-items-center" htmlFor="theme_color">Couleur du thème <div className="ml-2" style={{ backgroundColor: portal.theme_color, width: '50px', height: "30px", display: 'inline-block' }}></div></label>
                                        <ColorPicker id="theme_color" value={portal.theme_color} onChange={(e) => setProperty('theme_color', "#" + e.value)} inline />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="slogan">Slogan</label>
                                        <InputText id="slogan" className="w-full" value={portal.slogan} onChange={(e) => setProperty('slogan', e.target.value)} />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="layout_head">Entête</label>
                                        <div className="border-solid border-1 border-400">
                                            <Editor onTextChange={(value) => setProperty('layout_head', value)} content={portal.layout_head}></Editor>
                                        </div>
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="homepage_columns">Nombres de colonnes :</label>
                                        <InputNumber
                                            showButtons
                                            id="homepage_columns"
                                            value={portal.homepage_columns}
                                            onChange={(e) => setProperty('homepage_columns', e.value)}
                                            className="w-full"
                                            min={1} max={3} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="step_top_1">Top page 1 :</label>
                                        <Dropdown
                                            id="step_top_1"
                                            value={portal.step_top_1}
                                            onChange={(e) => setProperty('step_top_1', e.value)}
                                            options={steps}
                                            optionLabel="name"
                                            optionValue="id"
                                            filter showClear placeholder="Selectionner une page"
                                            className="w-full" />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="step_top_2">Top page 2 :</label>
                                        <Dropdown
                                            id="step_top_2"
                                            value={portal.step_top_2}
                                            onChange={(e) => setProperty('step_top_2', e.value)}
                                            options={steps}
                                            optionLabel="name"
                                            optionValue="id"
                                            filter showClear placeholder="Selectionner une page"
                                            className="w-full" />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="step_top_3">Top page 3 :</label>
                                        <Dropdown
                                            id="step_top_3"
                                            value={portal.step_top_3}
                                            onChange={(e) => setProperty('step_top_3', e.value)}
                                            options={steps}
                                            optionLabel="name"
                                            optionValue="id"
                                            filter showClear placeholder="Selectionner une page"
                                            className="w-full" />
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="homepage_show_sections">Afficher les sections</label>
                                        <div className="w-full">
                                            <InputSwitch
                                                id="homepage_show_sections"
                                                checked={portal.homepage_show_sections}
                                                onChange={(e) => setProperty('homepage_show_sections', e.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="homepage_show_search">Afficher le champ de recherche</label>
                                        <div className="w-full">
                                            <InputSwitch
                                                id="homepage_show_search"
                                                checked={portal.homepage_show_search}
                                                onChange={(e) => setProperty('homepage_show_search', e.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="homepage_show_categories">Afficher les catégories</label>
                                        <div className="w-full">
                                            <InputSwitch
                                                id="homepage_show_categories"
                                                checked={portal.homepage_show_categories}
                                                onChange={(e) => setProperty('homepage_show_categories', e.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="homepage_show_tags">Afficher les tags</label>
                                        <div className="w-full">
                                            <InputSwitch
                                                id="homepage_show_tags"
                                                checked={portal.homepage_show_tags}
                                                onChange={(e) => setProperty('homepage_show_tags', e.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="layout_footer">Pied de page</label>
                                        <div className="border-solid border-1 border-400">
                                            <Editor onTextChange={(value) => setProperty('layout_footer', value)} content={portal.layout_footer}></Editor>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </Card>
        </React.Fragment>
    );
}

export default PortalEdit;
