import React, { useState, useEffect, useContext } from "react";
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useDebounce } from 'primereact/hooks';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { appContext } from '../../../App'
import { Tag } from "primereact/tag";
import StepTemplatesDialog from "./steptemplatesDialog"

FilterService.register('custom_tags', (value, filters) => {
  if(!filters) return true
  return value.includes(filters)
});

FilterService.register('custom_categories', (value, filters) => {
  if(!filters) return true
  return value.find((c) => c ? c.id == filters : false)
});

function Steps() {
  const navigate = useNavigate();
  const [steps, setSteps] = useState([]);
  const [search, debouncedSearch, setSearch] = useDebounce('', 2000);
  const [templatesDialogVisible, setTemplatesDialogVisible] = useState(false)
  let { portalId } = useParams(null);
  const AppContext = useContext(appContext)
  const [filters, setFilters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    section: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    tags: { value: null, matchMode: FilterMatchMode.CUSTOM },
    categories: { value: null, matchMode: FilterMatchMode.CUSTOM },
    enabled: { value: null, matchMode: FilterMatchMode.EQUALS },
});

const [filterTags, setFilterTags] = useState([])
const [filterCats, setFilterCats] = useState([])

  const loadSteps = () => {
    let params = [
      { key: "join", value: "categories" },
      { key: "join", value: "step_templates" },
      { key: "include", value: "steps.id,steps.name,steps.section,steps.tags,steps.links,steps.enabled,steps.template_id,categories.id,categories.name" },
    ]
    if (search.length > 0) params.push({ key: "search", value: search });
    AppContext.api.getRecords("steps", params).then((res) => {
      setSteps(res.data.records);
      if(res.data?.records){
        let catsT = [];
        let tagsT = [];
        res.data.records.map((r) => {
          if(r?.categories){
            r.categories.map((c) => {
              if(c && !catsT.find((e) => e.value == c.id))
                catsT.push({value:c.id,label:c.name})
            })
          }
          tagsT = tagsT.concat(r.tags);
        })
        tagsT = tagsT.filter((value, index, array) => {
          return array.indexOf(value) === index;
        })
        setFilterTags(tagsT)
        setFilterCats(catsT)
      }
    });

  }
  useEffect(() => {
    loadSteps()
  }, [debouncedSearch]);

  const startContent = (
    <React.Fragment>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={search}
          placeholder="Rechercher"
          onChange={(e) => setSearch(e.target.value)} />
      </span>
    </React.Fragment>
  );

  const endContent = (
    <React.Fragment>
      <span className="p-buttonset">
        <Button
          label="Ajouter"
          icon="pi pi-check"
          severity="success"
          onClick={() => navigate(`/portal/${portalId}/steps/create`)}></Button>
        <Button
          label="Importer des modèles"
          icon="pi pi-check"
          outlined
          onClick={() => setTemplatesDialogVisible(true)} ></Button>
      </span>
    </React.Fragment>
  );

  const actionBtns = (item) => {
    return (
      <React.Fragment>
        <span className="p-buttonset">
          <Button
            icon="pi pi-pencil"
            onClick={() => navigate(`/portal/${portalId}/steps/${item.id}/edit`)}
            text raised
          ></Button>
          <Button
            icon="pi pi-trash"
            onClick={(event) => confirmRemoveStep(event, item)}
            severity="danger"
            text raised
          ></Button>
        </span>
      </React.Fragment>
    );
  };

  const confirmRemoveStep = (event, step) => {

    const accept = () => {
      AppContext.api.deleteRecords("steps", [step.id]).then((res) => {
        loadSteps()
      });
    };

    confirmPopup({
      target: event.currentTarget,
      message: 'Voulez-vous supprimer cette page?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'reject',
      accept
    });
  };

  const tags = (item) => {
    return (
      <React.Fragment>
        {item.tags.map((tag, index) => {
          return <Tag key={index}>
            <div className="flex align-items-center gap-2">
              <span className="text-base">{tag}</span>
            </div>
          </Tag>;
        })}
      </React.Fragment>
    );
  };

  const categories = (item) => {
    return (
      <React.Fragment>
        {item?.categories && item.categories.map((category, index) => {
          return (
            category ? <Tag key={index} style={{ background: category.color }}>
              <div className="flex align-items-center gap-2">
                <span className="text-base">{category.name}</span>
              </div>
            </Tag> : null
          );
        })}
      </React.Fragment>
    );
  };

  const links = (item) => {
    const links = [];
    item.links.map((link, index) => {
      links.push(link.type);
    }); const uniqueLinks = links.filter((v, i, a) => a.indexOf(v) === i);
    return (
      <React.Fragment>
        {uniqueLinks.map((link, index) => {
          return (
            <Tag key={index}>
              <div className="flex align-items-center gap-2">
                <span className="text-base">{link}</span>
              </div>
            </Tag>
          );
        })}
      </React.Fragment>
    );
  };

  const nameBody = (item) => {
    return item.template_id == null ? <strong>{item.name}</strong> : <em>{item.name}</em>
  };

  const tagsRowFilterTemplate = (options) => {
    return <Dropdown 
    value={options.value} 
    options={filterTags} 
    onChange={(e) => 
    options.filterApplyCallback(e.value)} 
    placeholder="Selectionner un tag" 
    className="p-column-filter" 
    showClear 
    style={{ minWidth: '12rem' }} />
};

const categoriesRowFilterTemplate = (options) => {
  return <Dropdown 
  value={options.value} 
  options={filterCats} 
  onChange={(e) => 
  options.filterApplyCallback(e.value)} 
  placeholder="Selectionner une catégorie" 
  className="p-column-filter" 
  showClear 
  style={{ minWidth: '12rem' }} />
};

const enabledBody = (field) => {
  return field.enabled ? <strong>Activé</strong> : <span>Désactivé</span>
};

const enabledRowFilterTemplate = (options) => {
  return <Dropdown 
  value={options.value} 
  options={[{value:true,label:'Activé'},{value:false,label:'Désactivé'}]} 
  onChange={(e) => 
  options.filterApplyCallback(e.value)} 
  placeholder="Statut" 
  className="p-column-filter" 
  showClear 
  style={{ minWidth: '12rem' }} />
};

  return (
    <React.Fragment>
      <ConfirmPopup />
      <StepTemplatesDialog visible={templatesDialogVisible} onImport={() => loadSteps()} onClose={() => setTemplatesDialogVisible(false)} />
      <Card
        className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
        header={
          <Toolbar
            start={startContent}
            end={endContent}
            className="shadow-none border-bottom-1 surface-border border-noround-bottom"
          />
        }
      >
        <DataTable
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          value={steps}
          tableStyle={{ minWidth: "50rem" }}
          scrollable
          scrollHeight="flex"
          className="fullHeightTable"
          sortMode="multiple"
          removableSort 
          globalFilterFields={['name', 'section', 'tags', 'categories.name']}
          filters={filters}
        >
          <Column 
            field="name" 
            sortable 
            filter 
            header="Nom"
            body={nameBody}></Column>
          <Column 
            field="section" 
            sortable 
            filter 
            header="Section"></Column>
          <Column 
            header="Tags" 
            filter 
            filterElement={tagsRowFilterTemplate}
            showFilterMatchModes={false}
            field="tags" 
            body={tags}></Column>
          <Column 
            header="Catégories" 
            filterField="categories" 
            field="categories" 
            filter 
            filterElement={categoriesRowFilterTemplate}
            showFilterMatchModes={false}
            body={categories}></Column>
          <Column 
            header="Liens" 
            body={links}></Column>
          <Column field="enabled" header="Statut" body={enabledBody} 
          dataType="boolean"
          filter
          filterElement={enabledRowFilterTemplate}
          sortable></Column>
          <Column 
            header="" 
            body={actionBtns} 
            style={{ width: '85px' }}></Column>
        </DataTable>
      </Card>
    </React.Fragment>
  );
}

export default Steps;
