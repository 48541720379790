import React, { useState, useEffect, useRef, useContext } from "react";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';
import { appContext } from '../../../../App'

function RelationsDialog(props) {
    const [isVisible, setVisible] = useState(false);
    const [items, setItems] = useState([])
    const [selectedItems, setSelectedItems] = useState([]);
    const [title, setTitle] = useState('Liens');
    const [linkTable, setLinkTable] = useState(null)
    const [linkTableId, setLinkTableId] = useState(null)
    const AppContext = useContext(appContext)
    const [filters, setFilters] = useState({
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        enabled: { value: null, matchMode: FilterMatchMode.EQUALS },
    });

    const loadItems = () => {
        let params = [
            { key: 'include', value: [props.mode + '.id', props.mode + '.name', props.mode + '.enabled'].join(',') }
        ]
        AppContext.api.getRecords(props.mode, params).then((res) => {
            setItems(res.data.records);
        })
    }
    
    useEffect(() => {
        if (props.plan && props.visible){
            switch (props.mode) {
                case 'portals': 
                    setTitle('Guichets'); 
                    setLinkTable('plan_portal')
                    setLinkTableId('portal_id')
                break;
                case 'forms_config': 
                    setTitle('Modèles de formulaires'); 
                    setLinkTable('form_plan')
                    setLinkTableId('form_id')
                break;
                case 'step_templates': 
                    setTitle('Modèles de pages'); 
                    setLinkTable('plan_step_template')
                    setLinkTableId('step_template_id')
                break;
            }
            loadItems()
        }
        else{
            setSelectedItems([])
            setLinkTable(null)
            setLinkTableId(null)
        }
    }, [props.plan, props.visible]);

    

    useEffect(() => {
        if (linkTable && props?.plan[linkTable]){
            setSelectedItems(props.plan[linkTable].map((it) => ({ id: it[linkTableId] })))
        }
    },[linkTable])

    useEffect(() => {
        setVisible(props.visible)
        if(!props.visible){
            setSelectedItems([])
            setLinkTable(null)
            setLinkTableId(null)
        }
    },[props.visible])


    const closeDialog = () => {
        props.onCancel();
        setVisible(false)
    }

    const saveItems = async () => {
        let stepOIds = props.plan.hasOwnProperty(linkTable) ? props.plan[linkTable].map((item) => item[linkTableId]) : []
        let stepNIds = selectedItems.map((item) => item.id)

        const idsToDelete = stepOIds.filter(id => !stepNIds.includes(id));
        const idsToInsert = stepNIds.filter(id => !stepOIds.includes(id));

        if (idsToDelete.length > 0) {
            let itemToDelete = props.plan[linkTable].filter((cs) => idsToDelete.includes(cs[linkTableId]))
            let itemIdsToDelete = itemToDelete.map((cs) => cs.id)
              await AppContext.api.deleteRecords(linkTable, itemIdsToDelete)
            // console.log('IDs à supprimer:', itemIdsToDelete);
        }

        if (idsToInsert.length > 0) {
            let itemsToInsert = idsToInsert.map((itemId) => { return { [linkTableId]: itemId, 'plan_id': props.plan.id } })
              await AppContext.api.postRecords(linkTable, itemsToInsert)
            // console.log('Nouveaux IDs à insérer:', itemsToInsert);
        }

        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: title+' modifiés', life: 3000 });
        props.onSave();
    }

    const footerContent = (
        <div>
            <Button
                label="Annuler"
                icon="pi pi-times"
                onClick={closeDialog}
                className="p-button-text" />
            <Button
                label={"Modifier"}
                icon="pi pi-check"
                onClick={saveItems} className="mr-0" />
        </div>
    );

    const enabledBody = (field) => {
        return field.enabled ? <strong>Activé</strong> : <span>Désactivé</span>
    };

    const enabledRowFilterTemplate = (options) => {
        return <Dropdown
            value={options.value}
            options={[{ value: true, label: 'Activé' }, { value: false, label: 'Désactivé' }]}
            onChange={(e) =>
                options.filterApplyCallback(e.value)}
            placeholder="Statut"
            className="p-column-filter"
            showClear
            style={{ minWidth: '12rem' }} />
    };

    return (
        <React.Fragment>
            <Dialog
                header={title}
                footer={footerContent}
                visible={isVisible}
                style={{ width: '80vw' }}
                onHide={closeDialog}>
                {items && items.length > 0 &&
                    <DataTable
                        paginator rows={10} rowsPerPageOptions={[10, 25, 50]}
                        selectionMode={'checkbox'}
                        selection={selectedItems} onSelectionChange={(e) => setSelectedItems(e.value)}
                        scrollable scrollHeight="400px" value={items}
                        dataKey="id"
                        sortMode="multiple"
                        removableSort
                        globalFilterFields={['name','enabled']}
                        filters={filters}>
                        <Column selectionMode={"multiple"} headerStyle={{ width: '3rem' }}></Column>
                        {/* <Column field={"id"} header={"ID"} /> */}
                        <Column field={"name"} filter header={"Nom"} />
                        <Column field={"enabled"} 
                        dataType="boolean"
                        filter
                        filterElement={enabledRowFilterTemplate} header={"Statut"} body={enabledBody} />
                    </DataTable>
                }
            </Dialog>
        </React.Fragment>
    );
}

export default RelationsDialog;
