import React, { useState, useEffect, useRef, useContext } from "react";
import { Menu } from "primereact/menu";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { InputTextarea } from 'primereact/inputtextarea';
import { Card } from 'primereact/card';
import { SplitButton } from "primereact/splitbutton";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { TabView, TabPanel } from 'primereact/tabview';
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { getPathByName } from "../../../routesUtils"
import { v4 as uuidv4 } from 'uuid';
import { appContext } from '../../../App'

function SubmissionView(props) {
  const navigate = useNavigate();
  const [submission, setSubmission] = useState([]);
  let { submissionId } = useParams(null);
  let { portalId } = useParams(null);
  const AppContext = useContext(appContext)

  const loadSubmission = () => {

    let params = [
      { key: 'join', value: 'forms' },
      { key: 'join', value: 'steps' },
      { key: 'include', value: 'submissions.id,submissions.webhook_url,submissions.webhook_response,submissions.mailto_address,submissions.mailto_response,submissions.webhook_content,submissions.mailto_content,submissions.created_at,forms.enabled,steps.id,steps.name,forms.id,forms.name' }
    ]
    AppContext.api.getRecord("submissions", [submissionId], params).then((res) => {
      setSubmission(res.data);
    })
  }
  useEffect(() => {
    loadSubmission()
  }, []);

  const startContent = (
    <React.Fragment>
      <h2 className="m-0">Soumission</h2>
    </React.Fragment>
  );

  const endContent = (
    <React.Fragment>
      <span className="p-buttonset">
        <Button label="Envoyer" icon="pi pi-check" onClick={() => sendSubmission()} severity="success" ></Button>
      </span>
    </React.Fragment>
  );

  const sendSubmission = () => {
    AppContext.api.get("form/resend/"+submissionId,).then((res) => {
      if(res.data.status){
        let msg = (res?.data?.message) ? res.data.message : 'Formulaire envoyé';
        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: msg, life: 3000 });
        navigate(getPathByName('portalSubmissions',{portalId:portalId}))
      }
      else{
        loadSubmission()
        AppContext.showNotification({ severity: 'error', summary: 'Erreur', detail: res.data.message, life: 3000 });
      }
    })
  }

  return (
    <React.Fragment>
      <Card
        className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
        header={
          <Toolbar
            start={startContent}
            end={endContent}
            className="shadow-none border-bottom-1 surface-border border-noround-bottom"
          />
        }
      >
        <div className="flex flex-grow-1">
          <div className="flex-grow-1 flex flex-column">
            <div className="flex-grow-1 flex" style={{ height: 0 }}>
              <TabView className="flex-grow-1 fullHeight">
                <TabPanel header="Webhook">
                  <div className="mb-2 mt-2">
                    <InputText 
                      className="w-full" 
                      disabled={!submission.webhook_url}
                      value={submission.webhook_url} />
                  </div>
                  <div className="mb-2 flex-grow-1 flex flex-column">
                    <label className="mb-2">Données envoyées</label>
                    <InputTextarea 
                      className="flex-grow-1 w-full" value={JSON.stringify(submission.webhook_content, null, 2)} />
                  </div>
                  <div className="flex-grow-1 flex flex-column">
                    <label className="mb-2">Réponse du webservices</label>
                    <InputTextarea 
                      disabled={!submission.webhook_response} 
                      className="flex-grow-1 w-full" 
                      value={JSON.stringify(submission.webhook_response)} />
                  </div>
                </TabPanel>
                <TabPanel header="Mail">
                  <div className="mb-3 mt-2">
                    <InputText 
                      className="w-full" 
                      disabled={!submission.mailto_address}
                      value={submission.mailto_address} />
                  </div>
                  <div className="mb-2 flex-grow-1 flex flex-column">
                    <label className="mb-2">Contenu du mail</label>
                    <InputTextarea 
                      disabled={!submission.mailto_content} 
                      className="flex-grow-1 w-full" 
                      value={submission.mailto_content} />
                  </div>
                  <div className="mb-2 flex-grow-1 flex flex-column">
                    <label className="mb-2">Réponse mail</label>
                    <InputTextarea 
                      disabled={!submission.mailto_response} 
                      className="flex-grow-1 w-full" 
                      value={submission.mailto_response} />
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
          <div style={{ width: "400px" }}>
            <TabView>
              <TabPanel header="Détails">
                <div className="gap-3 mb-3 mt-2">
                  <span> Page : </span>
                  {submission?.step_id?.id ? <span>{submission.step_id.name}</span> : <span>-</span>}
                </div>
                <div className="gap-3 mb-3 mt-2">
                  <span> Formulaire : </span>
                  {submission?.form_id?.id ? <span>{submission.form_id.name}</span> : <span>-</span>}
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
}

export default SubmissionView;
