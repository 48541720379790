import React, { useState, useEffect, useRef, useContext } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useDebounce } from 'primereact/hooks';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { getPathByName } from "../../../routesUtils"
import { appContext } from '../../../App'
import moment from 'moment';

function Submissions() {
  const navigate = useNavigate();
  const [submissions, setSubmissions] = useState([]);
  const [search, debouncedSearch, setSearch] = useDebounce('', 400);
  const [currentSubmission, setCurrentSubmission] = useState(undefined);
  let { portalId } = useParams(null);
  const AppContext = useContext(appContext)
  moment.locale();

  const loadSubmissions = () => {
    let params = [
      {key:'join',value:'forms'},
      {key:'join',value:'steps'},
      {key:'include',value:'submissions.id,submissions.created_at,steps.id,steps.name,forms.id,forms.name'}
    ]
    if (search.length > 0) params.push({ key: "search", value: search });
    AppContext.api.getRecords("submissions", params).then((res) => {
      setSubmissions(res.data.records);
    });
  }

  useEffect(() => {
    loadSubmissions()
  }, [debouncedSearch]);

  const startContent = (
    <React.Fragment>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={search} placeholder="Rechercher" onChange={(e) => setSearch(e.target.value)} />
      </span>
    </React.Fragment>
  );

  const actionBtns = (submission) => {
    return (
      <React.Fragment>
        <span className="p-buttonset">
          <Button
            icon="pi pi-pencil"
            onClick={() => navigate(getPathByName('portalViewSubmission',{portalId: portalId, submissionId:submission.id}))}
            text raised
          ></Button>
          <Button
            icon="pi pi-trash"
            onClick={(event) => confirmRemoveSubmission(event, submission)}
            severity="danger"
            text raised
          ></Button>
        </span>
      </React.Fragment>
    );
  };

  const confirmRemoveSubmission = (event, submission) => {

    const accept = () => {
      AppContext.api.deleteRecords("submissions", [submission.id]).then((res) => {
        loadSubmissions()
      });
    };

    confirmPopup({
      target: event.currentTarget,
      message: 'Voulez-vous supprimer cette soumission?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'reject',
      accept
    });
  };

  return (
    <React.Fragment>
      <ConfirmPopup />
      <Card
        className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
        header={
          <Toolbar
            start={startContent}
            className="shadow-none border-bottom-1 surface-border border-noround-bottom"
          />
        }
      >
        <DataTable
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          value={submissions}
          tableStyle={{ minWidth: "50rem" }}
          scrollable
          scrollHeight="flex"
          className="fullHeightTable"
          sortMode="multiple"
          removableSort
        >
          <Column field="step_id" header="Page" sortable body={(item) => {
            return (item.step_id?.id) ? item.step_id.name : <em>Page supprimée</em>
          }}></Column>
          <Column field="form_id" header="Formulaire" sortable body={(item) => {
            return (item.form_id?.id) ? item.form_id.name : <em>Formulaire supprimé</em>
          }}></Column>
          <Column field="created_at" header="Date" sortable body={(item) => {
            return (item?.created_at) ? moment(item.created_at).format('LL') : <em>-</em>
          }}></Column>
          <Column header="" style={{ width: '85px' }} body={actionBtns}></Column>
        </DataTable>
      </Card>
    </React.Fragment>
  );
}

export default Submissions;
