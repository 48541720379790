import React, { useState, useEffect, useContext } from "react";
import { Menu } from "primereact/menu";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import { getPathByName } from "../routesUtils"
import { appContext } from '../App'

function Sidebar(props) {
  const navigate = useNavigate();

  const [selectedPortal, setSelectedPortal] = useState(null);
  const [portals, setPortals] = useState([])
  const [menuItems, setMenuItems] = useState([]);
  const AppContext = useContext(appContext)
  const [appTitle, setAppTitle] = useState('FormAccess')
  let { portalId } = useParams(null);

  const selectPortal = (portal) => {
    navigate(getPathByName('portalDashboard', { portalId: portal.id }))
    window.location.reload()
  }

  useEffect(() => {
    if (props?.my?.portals) {
      setPortals(props.my.portals)
    }
    if (props?.my?.appName) {
      setAppTitle(props.my.appName)
    }
  }, [props.my.portals])

  useEffect(() => {
    if (portalId && portals.length) {
      // setPortalId(portalId)
      let portal = portals.find((p) => p.id == portalId)
      setSelectedPortal(portal)
    }
  }, [portals, portalId])

  const goToPortal = async() => {
    const res = await AppContext.api.post("user/quickaccess", {portalCode:selectedPortal.code})
    if(res?.data) window.open(res.data)
  }


  useEffect(() => {

      
    let itemsPortal = [
      {
        template: () => {
          return (
            <div className="bg-primary"><span className="inline-flex align-items-center gap-1 px-2 py-2">
              <span className="font-medium text-xl font-semibold py-1">
                {appTitle}
              </span>
            </span>
            </div>
          );
        },
      },{
        template: () => {
          return (
            <div className="flex  px-2 py-2">
              <strong>Mon compte</strong>
            </div>
          );
        },
        visible: props.mode == 'user'
      },{
        template: () => {
          return (
            <div className="flex  px-2 py-2">
              <strong>Configuration de la plateforme</strong>
            </div>
          );
        },
        visible: props.mode == 'config'
      },
      {
        separator: ['config','user'].includes(props.mode),
        visible:['config','user'].includes(props.mode)
      },
      {
        label: "Tableau de bord",
        icon: "pi pi-home",
        command: () => {
          navigate(getPathByName('home'));
        },
        visible: props.mode == 'user'
      },
      {
        label: "Mon compte",
        icon: "pi pi-user",
        command: () => {
          navigate(getPathByName('me'));
        },
        visible: props.mode == 'user'
      },
      {
        label: "Aide",
        icon: "pi pi-info-circle",
        command: () => {
          navigate(getPathByName('help'));
        },
        visible: props.mode == 'user'
      },
      {
        label: "Tableau de bord",
        icon: "pi pi-home",
        command: () => {
          navigate(getPathByName('config'));
        },
        visible: props?.my?.user?.root && props.mode == 'config'
      },
      {
        label: "Guichets",
        icon: "pi pi-globe",
        command: () => {
          navigate(getPathByName('configPortals'));
        },
        visible: props?.my?.user?.root && props.mode == 'config'
      },
      {
        label: "Utilisateurs",
        icon: "pi pi-user",
        command: () => {
          navigate(getPathByName('configUsers'));
        },
        visible: props?.my?.user?.root && props.mode == 'config'
      },
      {
        label: "Modèles de pages",
        icon: "pi pi-file",
        command: () => {
          navigate(getPathByName('configSteptemplates'));
        },
        visible: props?.my?.user?.root && props.mode == 'config'
      },
      {
        label: "Modèles de formulaires",
        icon: "pi pi-check-square",
        command: () => {
          navigate(getPathByName('configForms'));
        },
        visible: props?.my?.user?.root && props.mode == 'config'
      },
      {
        label: "Médias",
        icon: "pi pi-images",
        command: () => {
          navigate(getPathByName('configMedias'));
        },
        visible: props?.my?.user?.root && props.mode == 'config'
      },
      {
        label: "Plans",
        icon: "pi pi-key",
        command: () => {
          navigate(getPathByName('configPlans'));
        },
        visible: props?.my?.user?.root && props.mode == 'config'
      },
      {
        label: "Paramètres",
        icon: "pi pi-info-circle",
        command: () => {
          navigate(getPathByName('configSettings'));
        },
        visible: props?.my?.user?.root && props.mode == 'config'
      },
      {
        template: () => {
          return (
            <div className="flex  px-2 py-2">
              <Dropdown
                value={selectedPortal}
                onChange={(e) => selectPortal(e.value)}
                options={portals}
                optionLabel="name"
                placeholder="Choix du guichet"
                filter
                size="small"
                valueTemplate={selectedPortalTemplate}
                itemTemplate={portalOptionTemplate}
                className="flex-grow-1 sidebar-dropdown border-0 "
              />
              <Button label="Voir" className="ml-2" onClick={goToPortal}/>
            </div>
          );
        },
        visible: props.mode == 'portal',
      },
      {
        separator: (props.mode == 'portal' && (selectedPortal)),
        visible: props.mode == 'portal' && (selectedPortal && selectedPortal?.steps),
      },
      {
        label: "Tableau de bord",
        icon: "pi pi-home",
        visible: props.mode == 'portal',
        command: () => {
          navigate(getPathByName('portalDashboard', { portalId: portalId }));
        },
      },
      {
        label: "Pages",
        icon: "pi pi-file",
        visible: props.mode == 'portal' && (selectedPortal && selectedPortal?.perms?.steps == 1),
        command: () => {
          navigate(getPathByName('portalSteps', { portalId: portalId }));
        },
      },
      {
        label: "Formulaires",
        icon: "pi pi-check-square",
        visible: props.mode == 'portal' && (selectedPortal && selectedPortal?.perms?.forms == 1),
        command: () => {
          navigate(getPathByName('portalForms', { portalId: portalId }));
        },
      },
      {
        label: "Erreurs d'envoi",
        icon: "pi pi-envelope",
        visible: props.mode == 'portal' && (selectedPortal && selectedPortal?.perms?.submissions == 1),
        command: () => {
          navigate(getPathByName('portalSubmissions', { portalId: portalId }));
        },
      },
      {
        label: "Médias",
        icon: "pi pi-images",
        visible: props.mode == 'portal' && (selectedPortal && selectedPortal?.perms?.steps == 1),
        command: () => {
          navigate(getPathByName('portalMedias', { portalId: portalId }));
        },
      },
      {
        label: "Catégories",
        icon: "pi pi-folder-open",
        visible: props.mode == 'portal' && (selectedPortal && selectedPortal?.perms?.categories == 1),
        command: () => {
          navigate(getPathByName('portalCategories', { portalId: portalId }));
        },
      },
      {
        label: "Codes d'accès",
        icon: "pi pi-key",
        visible: props.mode == 'portal' && (selectedPortal && selectedPortal?.perms?.codes == 1),
        command: () => {
          navigate(getPathByName('portalCodes', { portalId: portalId }));
        },
      },
      {
        label: "Permissions",
        icon: "pi pi-id-card",
        visible: props.mode == 'portal' && (selectedPortal && selectedPortal?.perms?.permissions == 1),
        command: () => {
          navigate(getPathByName('portalPermissions', { portalId: portalId }));
        },
      },
      {
        label: "Paramètres du guichet",
        icon: "pi pi-info-circle",
        visible: props.mode == 'portal' && (selectedPortal && selectedPortal?.perms?.portal == 1),
        command: () => {
          navigate(getPathByName('portalEdit', { portalId: portalId }));
        },
      },
      {
        template: () => {
          return <div className="flex-grow-1"></div>;
        },
        className: "flex-grow-1",
      },
      {
        separator: true,
      },
      {
        label: "Guichets",
        icon: "pi pi-home",
        command: () => {
          navigate(getPathByName('portalDashboard', { portalId: props.my.portals[0].id }));
        },
        visible: props.mode != 'portal'
      },
      {
        label: "Configuration plateforme",
        icon: "pi pi-cog",
        visible: props?.my?.user?.root && props.mode != 'config',
        command: () => {
          navigate(`/config`);
        },
      },
      {
        label: 'Tableau de bord ' + (props?.my?.user?.name ? '(' + props.my.user.name + ')' : "Mon compte"),
        icon: "pi pi-user",
        visible: props.mode != 'user',
        command: () => {
          navigate("/");
        },
      },
      {
        label: "Déconnexion",
        icon: "pi pi-arrow-up-right",
        command: async () => {
          const res = await AppContext.api.post("logout")
          navigate("/login");
        },
      },
      {
        separator: true,
      },
      {
        template: () => {
          return (
            <span className="inline-flex align-items-center gap-1 px-2">
              <span className="font-medium text-sm font-semibold py-1">
                Form<span className="text-primary">Access</span>
              </span>
            </span>
          );
        },
      },
    ];
      setMenuItems(itemsPortal)
    

  }, [portals, portalId, selectedPortal, props.my.user, props.mode]);

  const selectedPortalTemplate = (option, props) => {
    if (option) {
      return <div>{option.name}</div>;
    }

    return <span>{props.placeholder}</span>;
  };

  const portalOptionTemplate = (option) => {
    return <div>{option.name}</div>;
  };

  return (
    <div className="sidebar">
      <div className="w-full flex flex-column sidebar-portal-dropdown">
        <div style={{ width: '270px' }} className="flex-grow-1 flex">
          {props.mode == 'portal' && selectedPortal && <Menu model={menuItems} className="w-full flex-grow-1 flex" />}
          {props.mode == 'config' && <Menu model={menuItems} className="w-full flex-grow-1 flex" />}
          {props.mode == 'user' && <Menu model={menuItems} className="w-full flex-grow-1 flex" />}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
