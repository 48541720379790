import React, { useState, useEffect, useRef, useContext } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { appContext } from '../../../App'
import LinkDialog from "./linkDialog";
import AIDialog from "./aiDialog";
import RevisionDialog from "./revisionDialog";
import Editor from "../../layout/Editor/Editor";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { getPathByName } from "../../../routesUtils"

function StepEdit(props) {
  const navigate = useNavigate();
  const [portalMetadata, setPortalMetadata] = useState([]);
  const [configMetadata, setConfigMetadata] = useState([]);
  const [step, setStep] = useState({});
  const [stepCategories, setStepCategories] = useState([])
  const [currentLink, setCurrentLink] = useState(false)
  const [aiDialogVisible, setAiDialogVisible] = useState(false);
  const [currentRevision, setCurrentRevision] = useState(false);
  const [currentTag, setCurrentTag] = useState('')
  let { stepId, steptemplateId } = useParams(null);
  let { portalId } = useParams(null);
  const AppContext = useContext(appContext)
  const [k, setK] = useState(0)

  const mainTableName = props.type == 'template' ? 'step_templates' : 'steps';
  const revisionTableName = props.type == 'template' ? 'step_template_revisions' : 'step_revisions';

  // const errorsField = [
  //   {
  //     property:'name',
  //     type:'required'
  //   }
  // ]

  useEffect(() => {
    if (props.type != 'template') {
      AppContext.api.get("metadata/portals").then((res) => {
        setPortalMetadata(res.data);
      });

      if (props?.mode && props.mode == 'create') {
        let s = {
          name: '',
          content: ' ',
          enabled: false,
          referenced: false,
          section: null,
          tags: [],
          meta_description: "",
          links: [],
          categories: []
        }
        setStep(s);
      }
      else {
        let params = []
        params.push({ key: "join", value: "category_step" })
        params.push({ key: "join", value: revisionTableName })
        params.push({ key: "include", value: `${mainTableName}.*,${revisionTableName}.id,${revisionTableName}.created_at,category_step.*` })

        AppContext.api.getRecord(mainTableName, [stepId], params).then((res) => {
          setStep(res.data);
          if (props.type != 'template' && res.data?.category_step) {
            let cats = res.data.category_step.map((catstep) => { return catstep.category_id })
            setStepCategories(cats)
          }
        });
      }
    }
    else {
      AppContext.api.get("metadata/config").then((res) => {
        setPortalMetadata(res.data);
      });

      if (props?.mode && props.mode == 'create') {
        let s = {
          name: '',
          content: ' ',
          enabled: false,
          section: null,
          tags: [],
          meta_description: "",
          links: [],
          categories: []
        }
        setStep(s);
      }
      else {
        let params = []
        params.push({ key: "join", value: revisionTableName })
        params.push({ key: "include", value: `${mainTableName}.*,${revisionTableName}.id,${revisionTableName}.created_at,${revisionTableName}.is_valid` })

        AppContext.api.getRecord(mainTableName, [steptemplateId], params).then((res) => {
          setStep(res.data);
        });
      }
    }
  }, []);

  const updateContent = (html) => {
    if (step && step?.content) {
      setStep({ ...step, content: html });
    }
  };

  const setEnabled = (stepState) => {
    setStep({ ...step, enabled: stepState });
  }

  
  const setFrontMenu = (stepMenu) => {
    setStep({ ...step, front_menu: stepMenu });
  }

  const setReferenced = (stepReferenced) => {
    setStep({ ...step, referenced: stepReferenced });
  }

  const setCategories = (stepCategories) => {
    if (props.type == 'template') {
      setStep({ ...step, categories: stepCategories });
    }
    else {
      setStepCategories(stepCategories)
    }
  }

  const setSection = (stepSection) => {
    setStep({ ...step, section: stepSection });
  }

  const setTags = (stepTags) => {
    setStep({ ...step, tags: stepTags });
  }

  const setName = (stepName) => {
    setStep({ ...step, name: stepName });
  }

  const setMetaDescription = (stepMetaDescription) => {
    setStep({ ...step, meta_description: stepMetaDescription });
  }

  const setLinks = (stepLinks) => {
    setStep({ ...step, links: stepLinks });
  }


  const goToStep = async () => {
    const res = await AppContext.api.post("user/quickaccess", { stepId: step.id })
    if (res?.data) window.open(res.data)
    // console.log(res)
  }

  const endContent = (
    <React.Fragment>
      {props.type != 'template' && step.template_id != null && <Button label="Dissocier" icon="pi pi-check" severity="warning" onClick={() => dissociate()} className="mr-2"></Button>}
      <span className="p-buttonset">
        <Button label="Sauvegarder" icon="pi pi-check" severity="success" onClick={() => saveStep()}></Button>
        <Button label="Générer avec I.A." icon="pi pi-wrench" outlined onClick={() => setAiDialogVisible(true)}></Button>
        <Button label="Afficher" icon="pi pi-eye" severity="primary" onClick={() => goToStep()}></Button>
      </span>
    </React.Fragment>
  );

  const dissociate = () => {
    AppContext.api.putRecords(mainTableName, [stepId], { template_id: null }).then((res) => {
      window.location.reload();
    })
  }

  const syncCats = async (stepId) => {
    if (props.type == 'template') return;

    let catOlds = step?.category_step ? step.category_step.map((item) => item.category_id) : []
    let catNIds = stepCategories

    const idsToDelete = catOlds.filter(id => !catNIds.includes(id));
    const idsToInsert = catNIds.filter(id => !catOlds.includes(id));

    if (idsToDelete.length > 0) {
      let codeToDelete = step.category_step.filter((cs) => idsToDelete.includes(cs.category_id))
      let codeIdsToDelete = codeToDelete.map((cs) => cs.id)
      await AppContext.api.deleteRecords("category_step", codeIdsToDelete)
      // console.log('IDs à supprimer:', codeIdsToDelete);
    }

    if (idsToInsert.length > 0) {
      let itemsToInsert = idsToInsert.map((catId) => { return { 'category_id': catId, 'step_id': stepId } })
      await AppContext.api.postRecords("category_step", itemsToInsert)
      // console.log('Nouveaux IDs à insérer:', itemsToInsert);
    }
  }

  const saveStep = () => {
    let errors = {}
    if (step.name == '') errors['name'] = 'Champs "Nom" requis.'

    if (Object.keys(errors).length > 0) {
      let msg = [];
      for (const name in errors) msg.push(errors[name])
      AppContext.showNotification({
        severity: 'error',
        summary: 'Erreurs',
        detail: msg.map((m, i) => <div key={i}>{m}</div>),
        life: 3000
      });
      return;
    }

    if (step?.id && step.id) {
      let id = props.type == 'template' ? steptemplateId : stepId
      AppContext.api.putRecords(mainTableName, [id], step).then((res) => {
        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Page sauvegardée', life: 3000 });
        if (props.type != 'template') syncCats(id)
      })
    }
    else {
      AppContext.api.postRecords(mainTableName, step).then((res) => {
        AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Page sauvegardée', life: 3000 });
        setStep({ ...step, id: res.data });
        if (props.type == 'template') {
          navigate(getPathByName('configEditSteptemplate', { steptemplateId: res.data }))
        }
        else {
          syncCats(res.data)
          navigate(getPathByName('portalEditStep', { portalId: portalId, stepId: res.data }))
        }
      })
    }
  }

  const linkTemplate = (link) => {
    return <><strong>{link.title}</strong><br /><small>{link.description}</small></>
  };

  const editLink = (link) => {
    setCurrentLink(link)
  }

  const saveCurrentLink = (link) => {
    var array = [...step.links];
    var index = array.indexOf(currentLink)
    if (index !== -1) {
      array[index] = link;
      setStep({ ...step, links: array });
    }
    setCurrentLink(false)
  }

  const confirmRemoveLink = (event, link) => {

    const accept = () => {
      var array = [...step.links];
      var index = array.indexOf(link)
      if (index !== -1) {
        array.splice(index, 1);
        setStep({ ...step, links: array });
      }
    };

    confirmPopup({
      target: event.currentTarget,
      message: 'Voulez-vous supprimer ce lien?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'reject',
      accept
    });
  };

  const actionLinksBtns = (link) => {
    return (
      <React.Fragment>
        <span className="p-buttonset">
          <Button
            text raised
            icon="pi pi-pencil"
            onClick={() => editLink(link)}
            size="small"
            severity="info"
          ></Button>
          <Button
            text raised
            icon="pi pi-trash"
            onClick={(event) => confirmRemoveLink(event, link)}
            size="small"
            severity="danger"
          ></Button>
        </span>
      </React.Fragment>
    );
  };

  const actionRevisionsBtns = (revision) => {
    return (
      <React.Fragment>
        {!revision.is_valid && <Button
          text raised
          icon="pi pi-eye"
          onClick={() => setCurrentRevision(revision)}
          size="small"
          severity="info"
        ></Button>}
      </React.Fragment>
    );
  };

  const addNewLink = () => {
    var array = [...step.links];
    const link = {
      uid: uuidv4(),
      title: '',
      description: '',
      type: 'link',
      link_url: ''
    }
    array.push(link)
    setStep({ ...step, links: array });
    editLink(link)
  }

  const insertContent = (content) => {
    updateContent(step.content + "<br />" + content)
    setK(k + 1)
    setAiDialogVisible(false)
  }

  const setRevision = (revision) => {
    setStep({ ...step, links: revision.links, content: revision.content });
    setK(k + 1)

  }

  const sortRevisions = (revisions) => {
    if (revisions)
      return revisions.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    return []
  }

  return (
    <React.Fragment>
      <AIDialog visible={aiDialogVisible} onGenerate={insertContent} onCancel={() => setAiDialogVisible(false)} />
      <LinkDialog type={props.type} currentLink={currentLink} onChange={saveCurrentLink} onClose={() => setCurrentLink(false)} />
      <RevisionDialog type={props.type} currentRevision={currentRevision} onLoadRevision={setRevision} onClose={() => setCurrentRevision(false)} revisions={sortRevisions(step[revisionTableName])} />
      <Card
        className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
        header={
          <Toolbar
            start={<h2 className="m-0">Page</h2>}
            end={endContent}
            className="shadow-none border-bottom-1 surface-border border-noround-bottom"
          />
        }
      >
        <div className="flex flex-grow-1" style={{ height: 0 }}>
          {step && step?.content && step.template_id == null &&
            <Editor key={k} onTextChange={updateContent} content={step.content}></Editor>}
          {step && step?.content && step.template_id != null &&
            <div className="flex flex-grow-1 flex-column">
              <div className="p-3 bg-yellow-400">Cette page est syncronisée avec un modèle. Vous devez la dissocier via le bouton ci-dessus avant de pouvoir modifier le contenu et les liens.</div>
              <div className="flex-grow-1 overflowY readOnlyContent">
                <div className="px-3 pb-3" dangerouslySetInnerHTML={{ __html: step.content }}></div></div></div>}
          <div>
            <div style={{ width: "400px" }}>
              <TabView>
                <TabPanel header="Options">
                  <div className="flex flex-column mb-4 gap-3">
                    <label htmlFor="name">Nom</label>
                    <InputText
                      id="name"
                      type="text"
                      value={step.name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-row gap-3 mb-3 mt-2">
                    <InputSwitch
                      id="stepEnabled"
                      checked={step.enabled}
                      onChange={(e) => setEnabled(e.value)} />
                    <label htmlFor="stepEnabled">Activé/Désactivé</label>
                  </div>
                  {props.type != 'template' && <div className="flex flex-row gap-3 mb-3">
                    <InputSwitch
                      id="stepReferenced"
                      checked={step.referenced}
                      onChange={(e) => setReferenced(e.value)} />
                    <label htmlFor="stepReferenced" >Référencée sur la page d'accueil</label>
                  </div>}
                  <div className="flex flex-row gap-3 mb-3 mt-2">
                    <InputSwitch
                      id="stepFrontMenu"
                      checked={step.front_menu}
                      onChange={(e) => setFrontMenu(e.value)} />
                    <label htmlFor="stepFrontMenu">Menu (sommaire titres 1, 2 & 3)</label>
                  </div>
                  <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="stepCategories">Catégories</label>
                    <MultiSelect
                      id="stepCategories"
                      value={props.type == 'template' ? step.categories : stepCategories}
                      onChange={(e) => setCategories(e.value)}
                      options={portalMetadata.categories}
                      optionLabel={props.type == 'template' ? null : "name"}
                      optionValue={props.type == 'template' ? null : "id"}
                      placeholder="Selectionner les catégories"
                      className="w-full" display="chip" />
                  </div>
                  <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="stepSection">Section</label>
                    <Dropdown
                      editable
                      value={step.section}
                      onChange={(e) => setSection(e.value)}
                      options={portalMetadata.sections}
                      showClear
                      placeholder="Créer ou selectionner une section"
                      className="w-full" />
                  </div>
                  <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="stepTags">Tags</label>
                    <MultiSelect id="stepTags"
                      panelHeaderTemplate={
                        <div className="p-2">
                          <div className="p-inputgroup flex-1">
                            <InputText
                              placeholder="Nouveau tag"
                              value={currentTag}
                              onChange={(e) => setCurrentTag(e.target.value)} />
                            <Button icon="pi pi-plus" severity="success" onClick={() => {
                              setPortalMetadata({ ...portalMetadata, ...{ tags: [...portalMetadata.tags, ...[currentTag]] } })
                              // setStep({ ...step, tags: [...step.tags,[currentTag]] });
                              setCurrentTag('')
                            }} />
                          </div></div>}
                      value={step.tags}
                      onChange={(e) => setTags(e.value)}
                      options={portalMetadata.tags}
                      display="chip"
                      placeholder="Selectionner les tags"
                      className="w-full" />
                  </div>
                  <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="stepMetaDescription">Description pour les moteurs de recherches</label>
                    <InputText
                      id="stepMetaDescription"
                      value={step.meta_description}
                      onChange={(e) => setMetaDescription(e.target.value)}
                      placeholder="Méta-description"
                      className="mr-2" />
                  </div>
                </TabPanel>
                <TabPanel header="Liens">
                  <ConfirmPopup />
                  <DataTable value={step.links} reorderableRows onRowReorder={(e) => setLinks(e.value)}>
                    {step.template_id == null && <Column rowReorder style={{ width: '3rem' }} />}
                    <Column key={"title"} columnKey={"title"} header={"Titre"} body={linkTemplate} />
                    {step.template_id == null && <Column header={<Button severity="success" size="small" onClick={addNewLink} icon="pi pi-plus"></Button>} body={actionLinksBtns} style={{ width: '85px' }}></Column>}
                  </DataTable>
                </TabPanel>
                <TabPanel header="Révisions">
                  <DataTable value={sortRevisions(step[revisionTableName])}>
                    <Column field={"created_at"} header={"Date"} body={(item) => moment(item.created_at).format('LLL')} />
                    <Column header="" body={actionRevisionsBtns} style={{ width: '45px' }}></Column>
                  </DataTable>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
        {/* forms primereact with title, content, categoriy picker, */}
      </Card>
    </React.Fragment>
  );
}

export default StepEdit;
