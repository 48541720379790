import React, { useState, useEffect, useRef, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { appContext } from '../../../App'

function PermissionDialog(props) {
    const [currentPermission, setCurrenPermission] = useState(undefined);
    const [isVisible, setVisible] = useState(false);
    const AppContext = useContext(appContext);
    const [existId, setExistId] = useState(false)
    const [email, setEmail] = useState('')

    const isUser = (mail) => {
        AppContext.api.post("/user/is", { email: mail }).then((res) => {
            if (res.data != 0) {
                setExistId(res.data);
            }
            else {
                setExistId(false);
            }
        });
    }

    useEffect(() => {
        if (props.permission) {
            setCurrenPermission(props.permission)
            setVisible(true)
            if(props?.permission?.user_id?.email){
                setEmail(props.permission.user_id.email)
                isUser(props.permission.user_id.email)
            }
        }
    }, [props.permission]);

    const closeDialog = () => {
        props.onCancel();
        setCurrenPermission(undefined)
        setVisible(false)
    }

    const permissionList = [
        {
            "name": "Guichet actuel",
            "value": "portal"
        },
        {
            "name": "Pages",
            "value": "steps"
        },
        {
            "name": "Forms",
            "value": "forms"
        },
        {
            "name": "Codes d'accès",
            "value": "codes"
        },
        {
            "name": "Catégories",
            "value": "categories"
        },
        {
            "name": "Gestionnaires",
            "value": "permissions"
        },
        {
            "name": "Erreurs d'envoi",
            "value": "submissions"
        }
    ]

    const savePermission = () => {
        let errors = {}
        if (currentPermission.email == '') errors['email'] = 'Champs "Email" requis.'
        console.log(existId)
        if (!existId) errors['user'] = 'Utilisateur non trouvé'

        if (Object.keys(errors).length > 0) {
            let msg = [];
            for (const name in errors) msg.push(errors[name])
            AppContext.showNotification({
                severity: 'error',
                summary: 'Erreurs',
                detail: msg.map((m, i) => <div key={i}>{m}</div>),
                life: 3000
            });
            return;
        }

        props.onSave({ ...currentPermission, ...{ user_id: existId } });
        setCurrenPermission(undefined)
        setVisible(false)
    }

    const checkEmail = () => {
        isUser(email)
    }

    const setCheckedPermission = (permissionName, value) => {
        setCurrenPermission({ ...currentPermission, [permissionName]: value });
    }

    const footerContent = (
        <div>
            <Button
                label="Annuler"
                icon="pi pi-times"
                onClick={closeDialog}
                className="p-button-text" />
            {((!currentPermission?.user_id?.id && existId) || currentPermission?.user_id?.id) &&
                <Button
                    label="Modifier"
                    icon="pi pi-check"
                    onClick={savePermission} className="mr-0" />}
        </div>
    );

    return (
        <React.Fragment>
            {currentPermission && <Dialog header="Gestionnaires" footer={footerContent} visible={isVisible} style={{ width: '300px' }} onHide={closeDialog}>
                <div className="flex flex-column mb-3 gap-3">
                    <label htmlFor="permissionLabel">Email</label>
                    {currentPermission?.user_id?.id && <strong>{currentPermission.user_id.email}</strong>}
                    {!currentPermission?.user_id?.id && <InputText id="permissionLabel" value={currentPermission.email} onChange={(e) => setEmail(e.target.value)} onBlur={(e) => checkEmail()} placeholder="Email" className="mr-2" />}

                </div>
                {permissionList.map((permissionItem) => {
                    return <div key={permissionItem.value} className="flex flex-column mb-3 gap-3">
                        <label htmlFor="permissionLabel">{permissionItem.name}</label>
                        <InputSwitch checked={currentPermission[permissionItem.value]} onChange={(e) => setCheckedPermission(permissionItem.value, e.value)} />
                    </div>
                })}

            </Dialog>}
        </React.Fragment>
    );
}

export default PermissionDialog;
