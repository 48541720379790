import React, { useState, useEffect, useRef, useContext } from "react";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import Editor from "../../layout/Editor/Editor";
import { TabView, TabPanel } from 'primereact/tabview';
import { useNavigate, useParams } from "react-router-dom";
import { Image } from 'primereact/image';
import { appContext } from '../../../App'

function Settings(props) {
    const [oldSettings, setOldSettings] = useState([]);
    const [settings, setSettings] = useState([]);
    const [tabs, setTabs] = useState([])
    const [portals, setPortals] = useState([])
    const AppContext = useContext(appContext)

    const loadSettings = () => {
        function onlyUnique(value, index, array) {
            return array.indexOf(value) === index;
        }

        AppContext.api.getRecords("settings", []).then((res) => {
            let ttabs = []
            res.data.records.map((s) => ttabs.push(s.category))
            ttabs = ttabs.filter(onlyUnique)
            setTabs(ttabs)
            setSettings(res.data.records);
            setOldSettings(JSON.parse(JSON.stringify(res.data.records)));
        });
    }

    const loadPortals = () => {
        let params = [
            { key: 'include', value: 'portals.id,portals.name' }
        ]
        AppContext.api.getRecords("portals", params).then((res) => {
            setPortals(res.data.records);
        });
    }

    useEffect(() => {
        loadSettings()
        loadPortals()
    }, []);

    const endContent = (
        <React.Fragment>
            <span className="p-buttonset">
                <Button label="Sauvegarder" icon="pi pi-check" onClick={() => saveSettings()} severity="success" ></Button>
            </span>
        </React.Fragment>
    );

    const saveSettings = () => {

        let errors = {}
        settings.map((s, si) => {
            if (s.required && s.content == '') {
                errors[s.name] = 'Champs "' + s.title + '" requis.'
            }
        })

        if (Object.keys(errors).length > 0) {
            let msg = [];
            for (const name in errors) msg.push(errors[name])
            AppContext.showNotification({
                severity: 'error',
                summary: 'Erreurs',
                detail: msg.map((m, i) => <div key={i}>{m}</div>),
                life: 3000
            });
            return;
        }

        let ids = []
        let objs = []
        settings.map((s, si) => {
            let os = oldSettings.find((osi) => osi.name == s.name)
            if (s.content != os.content) {
                ids.push(s.id)
                objs.push({ content: s.content })
            }
        })
        if (ids.length > 0) {
            AppContext.api.putRecords("settings", ids, objs).then((res) => {
                AppContext.showNotification({ severity: 'success', summary: 'Succès', detail: 'Paramètres sauvegardés', life: 3000 });
                loadSettings()
            })
        }
    }

    const setProperty = (name, value) => {
        var array = [...settings];
        var item = array.find((item) => item.name == name)
        if (item) {
            var index = array.indexOf(item)
            if (index !== -1) {
                item.content = value;
                array[index] = item;
                setSettings(array);
            }
        }
    }

    const selectImage = (property, url) => {
        setProperty(property, url)
    }

    const tabviewPt = {
        root: { className: 'flex flex-column flex-grow-1 p-0', style: { height: 0 } },
        panelContainer: { className: 'flex-grow-1', style: { 'height': 0, overflowY: 'auto' } }
    }

    return (
        <React.Fragment>
            <Card
                className="flex-grow-1 shadow-none border-1 surface-border fullHeightCard noPaddingCard"
                header={
                    <Toolbar
                        start={<h2 className="m-0">Paramètres de la plateforme</h2>}
                        end={endContent}
                        className="shadow-none border-bottom-1 surface-border border-noround-bottom"
                    />
                }>
                <div className="flex flex-grow-1">
                    <div className="flex-grow-1 flex flex-column">
                        <TabView pt={tabviewPt} className="flex-grow-1">
                            {tabs.map((settingTab, indexTab) =>
                                <TabPanel header={settingTab} key={indexTab}>
                                    {settings.map((settingItem, indexItem) =>
                                        settingItem.category == settingTab && <div className="formgrid grid" key={indexItem}>
                                            <div className="field col">
                                                <label htmlFor={'setting_' + settingItem.id}>{settingItem.title} : {settingItem.required && <>*</>}</label>
                                                {settingItem.type == 'input' &&
                                                    <InputText
                                                        id={'setting_' + settingItem.id}
                                                        className="w-full"
                                                        value={settingItem.content}
                                                        onChange={(e) => setProperty(settingItem.name, e.target.value)} />}
                                                {settingItem.type == 'textarea' &&
                                                    <InputTextarea
                                                        id={'setting_' + settingItem.id}
                                                        className="w-full"
                                                        value={settingItem.content}
                                                        onChange={(e) => setProperty(settingItem.name, e.target.value)} />}
                                                {settingItem.type == 'html' &&
                                                    <div className="border-solid border-1 border-400"><Editor
                                                        onTextChange={(value) => setProperty(settingItem.name, value)}
                                                        content={settingItem.content}></Editor></div>}
                                                {settingItem.type == 'media' &&
                                                    <div className="">
                                                        <Image src={settingItem.content} alt="Image" width="250" preview />
                                                        <div>
                                                            <Button onClick={() => AppContext.pickMedia((url) => selectImage(settingItem.name, url), () => { })} label="Sélectionner" />
                                                            <Button onClick={() => setProperty(settingItem.name, null)} label="Enlever" severity="danger" />
                                                        </div>
                                                    </div>}

                                                {settingItem.type == 'portal' &&
                                                    <div className="">
                                                        <Dropdown
                                                            value={parseInt(settingItem.content)}
                                                            onChange={(e) => setProperty(settingItem.name, e?.value ? e.value.toString() : null)}
                                                            options={portals}
                                                            optionLabel="name"
                                                            optionValue="id"
                                                            placeholder="Selectionner un guichet"
                                                            className="w-full" 
                                                            showClear/>
                                                    </div>}



                                                {settingItem?.description != '' && <small>{settingItem.description}</small>}
                                            </div>
                                        </div>
                                    )}
                                </TabPanel>
                            )}
                        </TabView>
                    </div>
                </div>
            </Card>
        </React.Fragment>
    );
}

export default Settings;
